






































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {
  InviteInlenerAction,
  Inlener, InlenerListAction, ToggleFavoriteInlener,
} from '@/store/inleners/types';
import VButton from '@/components/VButton.vue';
import FormField from '@/utilities/validation/formfield';
import ValidatedForm from '@/utilities/validation/validatedform';
import createList from '@/utilities/lists';
import emailValidator from '@/utilities/validators/email';
import {AxiosResponse} from 'axios';
import UserInfo from '@/interfaces/userInfo';
import {toReadableDateFormat} from '@/utilities/filters';
import debounce from '@/utilities/debounce';

@Component({
  components: {VButton},
  filters: {toReadableDateFormat},
})
export default class InlenersList extends Vue {

  get inviteInlenerFormValid() {
    return this.inviteInlenerForm.fieldValidity();
  }

  @Action('inlenerList', {namespace: 'inleners'}) private getInlenerList!: InlenerListAction;
  @Action('inviteInlenerUser', {namespace: 'inleners'}) private inviteInlenerUser!: InviteInlenerAction;
  @Action('toggleFavoriteInlener', {namespace: 'inleners'}) private toggleFavoriteInlener!: ToggleFavoriteInlener;

  @Getter('userInfo', {namespace: 'account'}) private userInfo!: UserInfo;

  private showServerError = false;
  private serverError = '';

  private inlenerList = createList<Inlener>({
    contains: '',
    page: 1,
    only_user: false,
    sort: '',
  });

  private inviteInlenerForm: ValidatedForm = new ValidatedForm({
    email: new FormField(emailValidator, ''),
  });

  private debounceFetchInleners = debounce(this.fetchInleners);

  private created() {
    this.fetchInleners();
  }

  @Watch('inlenerList.filters.contains')
  @Watch('inlenerList.filters.page')
  @Watch('inlenerList.filters.sort')
  private inlenersFilterWatcher() {
    this.debounceFetchInleners();
  }

  private fetchInleners() {
    this.inlenerList.fetching = true;

    const filters = { ...this.inlenerList.filters };

    this.getInlenerList(filters).then((result) => {
      this.inlenerList = {
        ...this.inlenerList,
        ...result,
      };
    }).catch(() => {
      this.inlenerList.models = [];
    }).finally(() => this.inlenerList.fetching = false);
  }

  private submitInviteInlenerUser() {
    this.showServerError = false;
    if (this.inviteInlenerForm.formValid()) {
      this.inviteInlenerUser({
        email: this.inviteInlenerForm.fields.email.value,
      }).then((response) => {
        (this.$refs['inlener-invited-modal'] as any).show();
        this.inlenerList.models = response.models;
      }).catch((response: AxiosResponse) => {
        this.serverError = response.data[0];
        this.showServerError = true;
      });
    }
  }

  private routeToInlener(inlener: Inlener) {
    this.$router.push({
      name: 'inleners-detail',
      params: {
        type: inlener.type,
        inlener_id: inlener.inlener_id.toString(),
      },
    });
  }

  private toggleFavorite(inlener: Inlener) {
    this.toggleFavoriteInlener({
        type: inlener.type,
        inlener_id: inlener.inlener_id,
      }).then(({ favorite }) => {
      inlener.favorite = favorite;
    });
  }

  private setSorting(attr: string) {
    const currentSort = this.inlenerList.filters.sort;
    if (currentSort.includes(attr)) {
      attr = currentSort.includes('-') ? attr : '-' + attr;
    }
    this.inlenerList.filters.page = 1;
    this.inlenerList.filters.sort = attr;
  }

  private getSortIcon(attr: string) {
    const currentSort = this.inlenerList.filters.sort;
    if (currentSort.includes(attr)) {
      return currentSort.includes('-') ? 'sort-up' : 'sort-down';
    }
    return 'sort';
  }
}
